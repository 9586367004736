import React from "react"
import Value from "aldoo-ra/Value"
import CMSRender from "aldoo-ra/CMS/cms-render"
import Typography from "aldoo-ra/Typography"
import { navigate } from "vike/client/router"

import { useRef, useEffect, useState } from "react"

const ToggleButton = ({
  isActive,
  onClick,
  activeLabel,
  inactiveLabel,
  disabled,
  width = "w-32",
}) => {
  const buttonRef = useRef(null)
  const [translateDistance, setTranslateDistance] = useState(0)

  const calculateDistance = () => {
    if (buttonRef.current) {
      const buttonWidth = buttonRef.current.offsetWidth
      const toggleBallWidth = 32 // 8 * 4 = 32px (w-8)
      const padding = 10 // 5px padding on each side
      setTranslateDistance(buttonWidth - toggleBallWidth - padding)
    }
  }

  useEffect(() => {
    calculateDistance()

    // Add resize listener
    window.addEventListener("resize", calculateDistance)

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", calculateDistance)
    }
  }, [width]) // Recalculate when width prop changes

  return (
    <button
      ref={buttonRef}
      onClick={onClick}
      disabled={disabled}
      className={`relative h-10 ${width} rounded-full border border-white ${
        isActive
          ? "bg-gradient-to-r from-primary via-primary_alt to-primary"
          : "bg-transparent"
      }`}
      style={{
        boxShadow: `
                  1px 1px 1px rgba(0, 0, 0, 0.2),
                  inset 2px 0px 0px rgba(0, 0, 0, 0.2), 
                  inset 2px 1px 0px rgba(255, 255, 255, 1)
                 `,
        backdropFilter: "blur(6px)",
        transition: "all 900ms",
      }}
    >
      {/* Toggle Ball */}
      <div
        className="absolute top-1/2 h-8 w-8 rounded-full bg-theme_white shadow-md transition-all duration-900 ease-out"
        style={{
          left: "5px",
          transform: `translateY(-50%) translateX(${
            isActive ? `${translateDistance}px` : "0"
          })`,
          boxShadow:
            "0px 2px 8px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.1)",
        }}
      />

      {/* Label */}
      <div
        className="absolute top-1/2 transition-all duration-900 ease-out"
        style={{
          transform: `translateY(-50%)`,
          right: isActive ? "auto" : "12px",
          left: isActive ? "12px" : "auto",
          textAlign: isActive ? "left" : "right",
        }}
      >
        <Typography
          variant="info_16"
          className={`px-1 md:px-2 lg:px-4  ${isActive ? "text-white" : "text-primary"}`}
        >
          {isActive ? activeLabel : inactiveLabel}
        </Typography>
      </div>
    </button>
  )
}

const PlatformSelector = () => {
  const [platform, setPlatform] = Value("site_platform")
  const [isTransitioning, setIsTransitioning] = Value("page_transition")

  const handlePlatformChange = async (newPlatform) => {
    if (platform === newPlatform || isTransitioning) return

    setIsTransitioning(true)
    setPlatform(newPlatform)

    if (newPlatform === "android") {
      navigate("/android")
    } else if (newPlatform === "ios") {
      navigate("/ios")
    } else {
      navigate("/")
    }

    await new Promise((resolve) => setTimeout(resolve, 500))
    setIsTransitioning(false)
  }

  return (
    // h-100vh is added for the positioning of the selector to the bottom of the visible viewport in sm-screens
    <div className="absolute w-full md:w-1/2 h-[100vh] right-0">
      <div
        className="absolute w-auto h-[110px] md:h-[120px] lg:h-[136px] bottom-[90px] md:bottom-auto md:top-[290px] lg:top-[320px]  xl:top-[380px] 2xl:top-auto 2xl:bottom-[150px] right-1/2 translate-x-1/2 md:left-[7%] md:right-auto lg:left-[10%] xl:left-[18%] 2xl:left-[8%] md:translate-x-0 rounded-[16px] overflow-hidden z-10"
        style={{
          boxShadow: `6px 12px 13px rgba(0, 0, 0, 0.15)`,
        }}
      >
        <CMSRender
          contentType="PlatformSelector"
          renderItem={(data) => (
            <div
              className="w-full h-full flex flex-col items-center justify-between bg-white/30 backdrop-blur-lg rounded-[16px] px-4 md:px-[18px] py-4 lg:py-[22px] mx-auto"
              style={{
                boxShadow: `
                  inset -1px 0px 0px rgba(0, 0, 0, 0.2),
                  inset 2px 1px 0px rgba(255, 255, 255, 1)
                `,
                backdropFilter: "blur(6px)",
              }}
            >
              <Typography
                variant="body_22_accent"
                className="text-gray_1 text-center my-0 "
              >
                {data.title || "Also available on"}
              </Typography>
              <div className="flex items-center justify-center gap-3 md:gap-4">
                {data.platforms &&
                  Object.entries(data.platforms).map(([key, value]) => (
                    <ToggleButton
                      key={key}
                      isActive={platform === key}
                      onClick={() => handlePlatformChange(key)}
                      activeLabel={value}
                      inactiveLabel={value}
                      disabled={isTransitioning}
                      width="w-[120px] md:w-[140px] xl:w-[160px]"
                    />
                  ))}
              </div>
            </div>
          )}
        />
      </div>
    </div>
  )
}

export default PlatformSelector
